import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import { has } from "lodash";
import { ToastProgrammatic as Toast } from "buefy";
import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "ujianList";
const modelPath = "/ujian/";

class UjianList extends ListModelBase {
  constructor() {
    let filterMap = {
      sub_departemen: null,
      stase: null,
      jenis: null,
      verifikasi: null,
      lulus: null
    }
    super(modelName, modelPath, filterMap);
  }

  initObservables() {
    super.initObservables();
    this.observables.errorList = [];
    this.observables.ujianBelumSet = {};
  }

  validateInline(idx) {
    let errorMap = this.observables.errorList[idx];
    if (this.observables.ujianList[idx].nilai == null) {
      if (has(errorMap, "nilai")) {
        errorMap = "";
      }
    } else if (this.observables.ujianList[idx].nilai_sikap == null) {
      if (has(errorMap, "nilai_sikap")) {
        errorMap = "";
      }
    }
  }

  getLoadData(data) {
    this.observables.ujianBelumSet = {};
    let ujianList = super.getLoadData(data);
    for (let ujian of ujianList) {
      let nilai = ujian['nilai'];
      let nilai_sikap = ujian['nilai_sikap'];
      ujian['nilai'] = nilai? parseFloat(nilai):nilai;
      ujian['nilai_sikap'] = nilai_sikap? parseFloat(nilai_sikap):nilai_sikap;
    }

    this.observables.errorList = [];
    let ujianBelumSet = ujianList.filter(
      d => d.hasil == 'Belum' || d.nilai == null
    );

    for (let ujian of ujianBelumSet) {
      this.observables.ujianBelumSet[ujian.id] = { nilai_sikap: ujian.nilai_sikap, hasil: ujian.hasil, nilai: ujian.nilai };
    }
    return ujianList;
  }

  getPayload() {
    let ujianSet = this.observables.ujianList.filter(
      d => has(this.observables.ujianBelumSet, d.id) &&
        (d.nilai_sikap != null || d.nilai != null || d.hasil != 'Belum')
    );
    let data = ujianSet.length > 0 ? { data_set: {} } : { data_set: null };
    for (let ujian of ujianSet) {
      let ver = ujian.nilai != null && ujian.hasil != "Belum" ? "Ya" : "Belum";
      ujian.verifikasi = ver;
      data.data_set[ujian.id] = { nilai_sikap: ujian.nilai_sikap, hasil: ujian.hasil, nilai: ujian.nilai, verifikasi: ver };
    }
    return data;
  }

  updateErrorFields(respErrorList) {
    /* digunakan untuk load error dari response */
    this.observables.errorList = respErrorList;
  }

  update(onSaved) {
    let updateURL = `${APP_CONFIG.baseAPIURL}${modelPath}penilaian/`;
    const data = this.getPayload();
    if (data.data_set == null) return;

    axios.patch(updateURL, data)
      .then((response) => {
        Toast.open("Data berhasil disimpan.");
        if (onSaved) {
          onSaved(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {  // Perlu handle 403 juga
          Toast.open("Data gagal disimpan.");
          this.updateErrorFields(error.response.data);
        }
      });
  }
}

export default UjianList;
