<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">UJIAN</p>
          <router-link
            v-if="isPSPD"
            :to="{ name: 'ujian-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
          <a
            v-if="canUpdate && ujianLength > 0"
            class="card-header-icon"
            @click.stop.prevent="saveObjects()"
          >
            <b-icon icon="save" size="is-small" class="has-text-light" />
          </a>
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <generic-filter-select
              v-if="isPSPD"
              class="filter3"
              apiPath="/programstudi/subdepartemen/"
              field="departemen"
              v-model="filterMap.sub_departemen"
            ></generic-filter-select>
            <generic-filter-select
              v-else
              class="filter3"
              apiPath="/stase/options/"
              field="stase"
              v-model="filterMap.stase"
            ></generic-filter-select>
            <generic-filter-select
              class="filter3"
              apiPath="/ujian/jenis/"
              field="jenis"
              v-model="filterMap.jenis"
            ></generic-filter-select>
            <verifikasi-select class="filter3" v-model="filterMap.verifikasi"></verifikasi-select>
            <lulus-select class="filter3" v-model="filterMap.lulus"></lulus-select>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small v-if="ujianList.length > 0" class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{ pagination.startRowNum + ujianList.length - 1 }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="(ujian, index) in ujianList"
              :key="ujian.id"
              :title="ujian.judul"
              :detailUrl="!canUpdate ? { name: 'ujian-detail', params: {id: ujian.id} } : null"
            >
              <template v-slot:date>
                <span class="is-pulled-right has-text-grey">
                  <small class="tanggal">{{ ujian.rencana_ujian }}</small>
                </span>
              </template>
              <template v-slot:content>
                <div v-if="!isPSPD">
                  {{ ujian.pspd }}
                  <b-tag class="is-pulled-right">{{ ujian.jenis }}</b-tag>
                </div>
                <div>
                  <small>
                    Tgl Maju:
                    <span>
                      <template v-if="ujian.tanggal_ujian">{{ ujian.tanggal_ujian }}</template>
                      <template v-else>-</template>
                    </span>
                  </small>
                </div>
                <div>
                  <small>{{ ujian.stase }}</small>
                </div>
                <template v-if="isPSPD">
                  <span class="data-tag">
                    <b-tag>{{ ujian.jenis }}</b-tag>
                  </span>
                  <span class="data-tag">
                    <div class="tag-control">
                      <b-taglist attached class="tags-custom">
                        <b-tag>Verifikasi:</b-tag>
                        <span class="tag is-grey">
                          <hasil-mark :hasil="ujian.verifikasi"></hasil-mark>
                        </span>
                      </b-taglist>
                    </div>
                  </span>
                </template>
                <div>
                  <!-- <b-field grouped group-multiline> -->
                  <template v-if="!isPSPD">
                    <div class="nilai-container">
                      <b-field
                        v-if="!includes(ujian.id, 'nilai_sikap') && canUpdate"
                        :type="getType(index)"
                      >
                        <b-numberinput
                          size="is-small"
                          min="0"
                          max="100"
                          step="0.01"
                          @input="validateInline(index)"
                          v-model="ujian.nilai_sikap"
                          placeholder="Nilai Sikap"
                          :controls="false"
                        ></b-numberinput>
                      </b-field>
                      <b-tag v-else-if="ujian.nilai_sikap == null">Nilai Sikap: -</b-tag>
                      <b-tag v-else>Nilai Sikap: {{ ujian.nilai_sikap }}</b-tag>
                    </div>
                    <div class="nilai-container">
                      <b-field
                        v-if="!includes(ujian.id, 'nilai') && canUpdate"
                        :type="getType(index)"
                      >
                        <b-numberinput
                          size="is-small"
                          min="0"
                          max="100"
                          step="0.01"
                          @input="validateInline(index)"
                          v-model="ujian.nilai"
                          placeholder="Nilai"
                          :controls="false"
                        ></b-numberinput>
                      </b-field>
                      <b-tag v-else-if="ujian.nilai == null">Nilai: -</b-tag>
                      <b-tag v-else>Nilai: {{ ujian.nilai }}</b-tag>
                    </div>
                  </template>
                  <div class="hasil">
                    <b-field v-if="includes(ujian.id, 'hasil') && canUpdate">
                      <b-select size="is-small" v-model="ujian.hasil" placeholder="--- Hasil ---">
                        <option class="nofilter" value="Belum">--- Hasil ---</option>
                        <option value="Belum">Belum</option>
                        <option value="Lulus">Lulus</option>
                        <option value="Tidak Lulus">Tidak Lulus</option>
                      </b-select>
                    </b-field>
                    <b-tag v-else>
                      <template v-if="isPSPD">Kelulusan:</template>
                      {{ ujian.hasil }}
                    </b-tag>
                  </div>
                </div>
              </template>
            </box>
            <box v-if="ujianList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>

          <!-- <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="ujianList"
            :mobile-cards="false"
          >
            <b-table-column label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="judul" label="Judul" v-slot="props">
              <span v-if="isPSPD" class="tanggal">{{ props.row.tanggal_ujian }}</span>
              <span v-else class="tanggal">{{ props.row.rencana_ujian }}</span>
              <br />
              <span>{{ props.row.stase }}</span>
              <br />
              <router-link
                :to="{ name: 'ujian-detail', params: {id: props.row.id} }"
              >{{ props.row.judul }} [{{ props.row.jenis }}]</router-link>
            </b-table-column>
            <b-table-column
              v-if="!isPSPD"
              field="ppds"
              :label="ppdsTglLabel"
              class="capitalize"
              v-slot="props"
            >
              <span :class="{ tanggal : !isPSPD }">
                <template v-if="props.row.tanggal_ujian">{{ props.row.tanggal_ujian }}</template>
                <template v-else>-</template>
              </span>
              <br />
              <template v-if="!isPSPD">{{ props.row.pspd }}</template>
            </b-table-column>
            <b-table-column v-else field="penguji" label="Penguji" v-slot="props">
              <p v-for="penguji in props.row.penguji_set" :key="penguji.id">{{ penguji.nama }}</p>
            </b-table-column>
            <template v-if="!isPSPD">
              <b-table-column field="nilai_sikap" label="Nilai Sikap" v-slot="props">
                <b-field
                  v-if="!includes(props.row.id, 'nilai_sikap') && canUpdate"
                  :type="getType(props.index)"
                >
                  <b-numberinput
                    class="nilai"
                    min="0"
                    max="100"
                    step="0.01"
                    @input="validateInline(props.index)"
                    v-model="props.row.nilai_sikap"
                    :controls="false"
                  ></b-numberinput>
                </b-field>
                <span v-else-if="props.row.nilai_sikap == null" class="icon has-text-info">
                  <b-icon size="is-small" icon="minus" />
                </span>
                <template v-else>{{ props.row.nilai_sikap }}</template>
              </b-table-column>
              <b-table-column field="nilai" label="Nilai" v-slot="props">
                <b-field
                  v-if="!includes(props.row.id, 'nilai') && canUpdate"
                  :type="getType(props.index)"
                >
                  <b-numberinput
                    class="nilai"
                    min="0"
                    max="100"
                    step="0.01"
                    @input="validateInline(props.index)"
                    v-model="props.row.nilai"
                    :controls="false"
                  ></b-numberinput>
                </b-field>
                <span v-else-if="props.row.nilai == null" class="icon has-text-info">
                  <b-icon size="is-small" icon="minus" />
                </span>
                <template v-else>{{ props.row.nilai }}</template>
              </b-table-column>
            </template>
            <b-table-column field="hasil" label="Hasil" v-slot="props">
              <b-select
                v-if="includes(props.row.id, 'hasil') && canUpdate"
                v-model="props.row.hasil"
              >
                <option value="Belum">Belum</option>
                <option value="Lulus">Lulus</option>
                <option value="Tidak Lulus">Tidak Lulus</option>
              </b-select>
              <template v-else>{{ props.row.hasil }}</template>
            </b-table-column>
            <b-table-column v-if="isPSPD" field="verifikasi" label="Verifikasi" v-slot="props">
              <hasil-mark :hasil="props.row.verifikasi"></hasil-mark>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>-->

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clone, has } from "lodash";
import { mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import UjianList from "../models/ujianList.js";

export default {
  name: "UjianList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
    LulusSelect: () => import("@/apps/aktivitas/components/LulusSelect.vue"),
    VerifikasiSelect: () =>
      import("@/apps/aktivitas/components/VerifikasiSelect.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  data() {
    this.ujianListMdl = new UjianList();
    this.listMdl = this.ujianListMdl; // bikin alias
    let obv = this.ujianListMdl.getObservables();
    obv.canUpdate = false;
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isPSPD", "isPreceptor"]),
    ppdsTglLabel() {
      return this.isPSPD ? "Tgl Ujian" : "Mahasiswa";
    },
    ujianLength() {
      return this.ujianList.length;
    },
  },
  methods: {
    includes(id, type) {
      if (has(this.ujianBelumSet, id)) {
        if (type == "hasil") return this.ujianBelumSet[id][type] == "Belum";
        return this.ujianBelumSet[id][type];
      }
      return null;
    },
    needConfirmation() {
      if (!this.canUpdate) return this.canUpdate;
      let ujianBelumSet = this.ujianList.filter(
        (d) =>
          this.ujianBelumSet[d.id].hasil != d.hasil ||
          this.ujianBelumSet[d.id].nilai != d.nilai ||
          this.ujianBelumSet[d.id].nilai_sikap != d.nilai_sikap
      );
      return ujianBelumSet.length > 0;
    },
    filter() {
      this.ujianListMdl.applyFilter();
      this.canUpdate = this.isPreceptor && this.filterMap.verifikasi == "Belum";
    },
    applyFilter() {
      if (this.needConfirmation()) {
        this.showConfirmationDlg(() => {
          this.saveObjects(this.filter);
        });
      } else {
        this.filter();
      }
    },
    getType(idx) {
      if (has(this.errorList[idx], "nilai")) {
        return this.errorList[idx]["nilai"] ? "is-danger" : "";
      }
      return "";
    },
    validateInline(idx) {
      this.ujianListMdl.validateInline(idx);
    },
  },
  mixins: [onlineCheckMixin, paginationMixin],
  activated() {
    if (
      has(this.$route.params, "verifikasi") ||
      has(this.$route.params, "stase")
    ) {
      this.ujianListMdl.reset();
      this.filterMap.verifikasi = this.$route.params.verifikasi;
      this.filterMap.sub_departemen = this.$route.params.sub_departemen;
      this.filterMap.stase = this.$route.params.stase;
      this.filterMap.jenis = null;
      this.filterMap.lulus = null;
      this.filterMap.cari = "";
      this.filterPrevMap = clone(this.filterMap);
    }
    this.canUpdate = this.isPreceptor && this.filterMap.verifikasi == "Belum";
    this.fetchData();
  },
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
.tanggal {
  font-size: 0.8rem !important;
}

.nilai-container {
  float: left;
  margin-right: 5px;
  max-width: 100px;
}

.hasil {
  float: left;
  max-width: 100px;
}

.data-tag {
  float: left;
  margin-right: 5px;
}
</style>